import DistributionTypes from '@/config/DistributionTypes.js'

export default async ({ route, store }) => {
  const distributionFromUrl = parseInt(route.query.distribution)

  // TODO: temporary fix for legacy app users. Remove "params" integration after all apps have been upgraded
  const onLocationId = route.params.onLocationId ?? route.query.table
  const isOnLocationDirect = !!(route.params.direct ?? Object.keys(route.query).includes('direct'))

  // Apply UTM tagging to session's "tracking"
  const { utm_source, utm_medium } = route.query

  // Tracking is important to determine the origin of the order
  // If the order is originated from the merchant's website, this might affect the Bistroo commission charged on that order
  if (utm_medium || utm_source) store.dispatch('session/storeTracking', { utm_source, utm_medium })

  // For TakeAway & Delivery
  if ([DistributionTypes.Delivery, DistributionTypes.TakeAway].includes(distributionFromUrl)) {
    await store.dispatch(
      'session/storeDistributionType',
      distributionFromUrl,
    )
  }

  // For On Location (direct)
  if (onLocationId) {
    return await Promise.all([
      // Store distribution type
      // store.dispatch('session/storeDistributionType', DistributionTypes.OnLocation),

      // // Store the on location identifier (e.g. tafel-10)
      // store.dispatch('session/storeOnLocationIdentifier', onLocationId),

      // If we are ordering "directly" (immediately) on location, we don't need time slots later on
      store.dispatch('session/storeOnLocationDirect', isOnLocationDirect),
    ])
  }

  // Clear persisted On Location (direct) scenarios from store
  await Promise.all([
    store.dispatch('session/storeOnLocationIdentifier', null),
    store.dispatch('session/storeOnLocationDirect', false),
  ])
}
