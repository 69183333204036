<script setup lang="ts">
import { computed, useSSRContext, reactive , onServerPrefetch, onBeforeMount, ref, onMounted } from 'vue'
import { useStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'
import { ClientOnly } from 'vite-plugin-vue-ssr'
import { useI18n } from 'vue-i18n'
import xss from 'xss'
import { injectHead } from '@unhead/vue'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import { useSegment } from '@/composables/useSegment'
import stripTags, { basicTags } from '@/utils/strip_tags.js'
import { formatPrice } from '@/plugins/globals/filters'
import parametersToStore from '@/pages/establishment/helpers/parameters-to-store'
import createStructuredData from '@/pages/establishment/helpers/create-establishment-structured-data'
import { useEstablishmentHttp } from '@/http/establishmentHttp'
import JsonLd from '@/components/partials/JsonLd.vue'
import Cart from '@/components/Cart/redesign/CartDesktop.vue'
import EstablishmentCategory from '@/pages/establishment/components/redesign/EstablishmentCategory/EstablishmentCategory.vue'
import LoadingEstablishmentCategory from '@/pages/establishment/components/redesign/EstablishmentCategory/LoadingEstablishmentCategory.vue'
import EstablishmentFAQ from './components/EstablishmentFAQ/EstablishmentFAQ.vue'
import Icon from '@/components/partials/Icon.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import EstablishmentHeader from './components/redesign/EstablishmentHeader/EstablishmentHeader.vue'
import EstablishmentHeaderLoading from './components/redesign/EstablishmentHeader/EstablishmentHeaderLoading.vue'
import EstablishmentToolbarTop from './components/redesign/EstablishmentToolbarTop/EstablishmentToolbarTop.vue'
import EstablishmentDiscounts from './components/EstablishmentDiscounts.vue'
import EstablishmentDiscountsLoading from './components/EstablishmentDiscountsLoading.vue'
import FoodModal from '@/pages/establishment/components/Modals/FoodModal/FoodModal.vue'
import InfoModal from '@/pages/establishment/components/Modals/InfoModal/InfoModal.vue'
import AddReviewModal from '@/pages/establishment/components/Modals/AddReviewModal/AddReviewModal.vue'
import RemarkModal from '../checkout/components/redesign/RemarkModal.vue'
import Toolbar from '@/components/Navigation/Toolbar.vue'
import Warning from '@/components/partials/Warning.vue'
import FreeDeliveryNoticeDrawer from '@/components/Cart/FreeDeliveryNotice/FreeDeliveryNoticeDrawer/FreeDeliveryNoticeDrawer.vue'
import Container from '@/components/Structure/Container/Container.vue'
import DefaultLayout from '@/layouts/default.vue'
import { ESTABLISHMENT_VIEWED, CHECKOUT_STARTED } from '@/plugins/globals/segment/handlers'
import { useGa } from '@/composables/useGa'
import { DistributionType, type Establishment, EstablishmentMode } from '@/http/models/Establishment'
import type { Location } from '@/types/Location'
import { useCitiesHttp } from '@/http/citiesHttp'
import LocationModal from '../checkout/components/LocationModal.vue'
import type { BasketProduct } from '@/types/BasketProduct'
import { useBasket } from '@/composables/basket'

const store = useStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { localizedRoute } = useLocalizedRouter()
const ga = useGa()
const segment = useSegment()

const establishment = computed<Establishment | null>(() => store.getters['establishment/establishment'])
const cartEstablishment = computed<Establishment | null>(() => store.getters['cart/establishment'])

type State = {
  remarkProduct: BasketProduct | null
  showRemarkModal: boolean
  showLocationModal: boolean
  locationModalError: string | null
  changeDistributionTo: DistributionType | null
}

const state = reactive<State>({
  remarkProduct: null,
  showRemarkModal: false,
  showLocationModal: false,
  locationModalError: null,
  changeDistributionTo: null,
})

const filteredProducts = computed(() =>
  (establishment.value?.productSets ?? []).flatMap(productSet => {
    const filteredProductsInSet = productSet.products.filter(product =>
      product.title.toLowerCase().includes(search.value.toLowerCase())
    )

    return filteredProductsInSet.length > 0 ? [{ ...productSet, products: filteredProductsInSet }] : []
  })
)

const basketInfo = computed(() => store.getters['cart/basketInfo'])
const distributionType = computed<DistributionType>(() => store.getters['session/distributionType'])
const location = computed<Location | null>(() => store.getters['session/location'])
const hasItemsFromEstablishment = computed(() => store.getters['cart/hasItemsFromEstablishment'])

const structuredData = ref()

const search = ref('')

function updateSearch(text: string) {
  search.value = text
}

const noLocationButShould = computed(() => distributionType.value === DistributionType.Delivery && location.value === null)

const seoContent = computed(() => {
  if (establishment.value?.content?.content) {
    return xss(stripTags(establishment.value.content.content, basicTags))
  }

  return null
})

const totalPayable = computed(() => formatPrice(basketInfo.value?.costs?.subtotal ?? 0))

const cityHttp = useCitiesHttp()

const head = injectHead()

onServerPrefetch(async () => {
  // TODO: implement pro6pp for zip code and house number
  // if (route.query.zip_code !== undefined) {
  //   const location: Location = {
  //     id: null,
  //     street: '',
  //     houseNumber: '',
  //     zipcode: route.query.zip_code as string,
  //     city: '',
  //     country: store.state.session.country,
  //     coordinates: {
  //       lat: 0,
  //       lng: 0,
  //     },
  //     title: null,
  //   }

  //   await Promise.all([
  //     store.dispatch('session/storeCurrentLocation', location),
  //     store.dispatch('session/storeTemporaryLocation', location),
  //   ])

  //   const cookieOptions = {}

  //   if (store.state.session.country !== 'nl') {
  //     cookieOptions.path = `/${store.state.session.country}`
  //   }

  //   ctx?.response.cookie('location', Buffer.from(JSON.stringify(location)).toString('base64'), cookieOptions)
  // }

  try {
    await initEstablishment()

    await store.dispatch('establishment/setSSR', true)

    const establishment = store.getters['establishment/establishment'] as Establishment

    structuredData.value = createStructuredData(establishment)

    let categories = establishment.categories

    // delivery methods string
    const deliveryMethods = []
    if (establishment.distributionTypes.includes(DistributionType.TakeAway)) {
      deliveryMethods.push(t('seo.distribution_takeaway'))
    }

    if (establishment.distributionTypes.includes(DistributionType.Delivery)) {
      deliveryMethods.push(t('seo.distribution_delivery'))
    }

    const deliveryString =
      deliveryMethods.length > 1
        ? t('seo.distribution_multiple', {
          method1: deliveryMethods[0],
          method2: deliveryMethods[1],
        })
        : deliveryMethods[0]

    const seo = {
      categoriesFirst:
        categories.length === 1
          ? categories[0].name
          : categories
            .slice(0, Math.min(2, categories.length - 1))
            .map((cat) => cat.name)
            .join(', '),
      categoriesLast:
        categories.length >= 2 ? categories[categories.length - 1].name : '',
      distribution: deliveryString,
    }

    let description = t('seo.description', {
      establishment: establishment.title,
      street: establishment.location.street,
      city: establishment.location.city,
      categories: seo.categoriesLast
        ? t('seo.categories_or', {
          categoriesFirst: seo.categoriesFirst,
          categoriesLast: seo.categoriesLast,
        })
        : seo.categoriesFirst,
      distribution: seo.distribution,
    })

    if (establishment.content?.meta?.description) {
      description = xss(stripTags(establishment.content.meta.description, basicTags))
    }

    let title = t('seo.title', {
      establishment: establishment.title,
      categories:
        seo.categoriesFirst +
        (seo.categoriesLast ? `, ${  seo.categoriesLast}` : ''),
    })

    if (establishment.content?.meta?.title) {
      title = establishment.content.meta.title
    }

    const image = {
      path: `https://res.cloudinary.com/dk91ryeja/image/upload/co_rgb:FF5F59,g_center,l_text:Ubuntu_52_bold_center:${encodeURIComponent(
        establishment.title
      )},x_350,y_-15,w_450,c_fit/v1661155365/merchant-previewimage-1200x630_mclber.jpg`,
      width: 1200,
      height: 627,
      type: 'png',
    }

    head?.push({
      title,
      meta: [
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'twitter:title',
          content: title,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: image.path,
        },
        {
          hid: 'og:image:type',
          property: 'og:image:type',
          content: image.type,
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: image.width,
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: image.height,
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'twitter:description',
          content: description,
        },
        ...((import.meta.env.VITE_BUILD_ENV !== 'production' || establishment.mode === EstablishmentMode.Test) ? [
          {
            name: 'robots',
            content: 'noindex',
          }
        ] : [])
      ],
    })
  } catch (e) {
    // if the establishment doesnt exist, try to navigate
    // to the city page, if that doesnt exist, navigate to the homepage
    try {
      await cityHttp.city(store.state.session.country, route.params.city)

      ctx?.redirect(`/${route.params.city}`)
    } catch (e) {
      ctx?.redirect('/')
    }
  }
})

onBeforeMount(async () => {
  if (!store.state.establishment.ssr) {
    await store.dispatch('establishment/resetState')
  }
})

onMounted(async () => {
  await parametersToStore({
    route: route,
    store: store,
  })

  // if (route.query.zip_code !== undefined) {
  //   await router.replace(localizedRoute({ name: 'establishment', params: { city: route.params.city, slug: route.params.slug }}))
  // }

  if (store.state.establishment.ssr) {
    await store.dispatch('establishment/setSSR', false)
  } else {
    await initEstablishment()
  }

  segment.handle(ESTABLISHMENT_VIEWED, {
    establishment: establishment.value,
    tracking: store.getters['session/tracking'],
  })

  ga.trackViewEstablishment(establishment.value)

  await doesNotSupportOnLocationOrdersModal()

  if (!establishment.value?.distributionTypes.includes(store.getters['session/distributionType'])) {
    const availableDistTypes = establishment.value?.distributionTypes.filter(type => type !== DistributionType.OnLocation)

    await store.dispatch(
      'session/storeDistributionType',
      availableDistTypes
    )

    await store.dispatch('modal/show', {
      closable: true,
      title: t('errors.only.distribution.title'),
      message: t(`errors.only.distribution.${availableDistTypes}`, {
        establishment: store.getters['establishment/establishment'].title,
      }),
      callback: [
        {
          label: t('buttons.continue'),
        },
      ],
    })
  }

  if (store.state.cart.basketInfo !== null) {
    const distType = store.state.cart.distributionType!
    const slug = store.state.cart.basketInfo.establishment.slug
    const country = store.state.cart.basketInfo.establishment.city.country.code.toLowerCase()
    const city = store.state.cart.basketInfo.establishment.city.slug

    const [ establishment, distributionInfo ] = await Promise.all([
      establishmentHttp.establishment(
        slug,
        country ?? route.params.country ?? store.state.session.country,
        city ?? route.params.city,
        null
      ),
      establishmentHttp.establishmentByDistributionType(
        slug,
        country ?? route.params.country ?? store.state.session.country,
        city ?? route.params.city,
        distType,
        location.value
      )
    ])

    await store.dispatch('cart/setEstablishment', { ...establishment, ...distributionInfo })
  }
})

const ctx = import.meta.env.SSR ? useSSRContext() : null

const establishmentHttp = useEstablishmentHttp()

async function fetchEstablishment(slug: string, country: string, city: string, hash: string | null = null) {
  const establishment = await establishmentHttp.establishment(
    slug,
    country ?? route.params.country ?? store.state.session.country,
    city ?? route.params.city,
    hash
  )

  let distType = store.getters['session/distributionType'] as DistributionType | null
  const onLocationId = route.params.onLocationId as string | undefined

  const hasOnLocationId = import.meta.env.SSR ? onLocationId !== '' : onLocationId !== undefined

  if (hasOnLocationId) {
    distType = 3

    await store.dispatch('session/storeOnLocationIdentifier', onLocationId)
  }

  if (distType === null || (distType === 3 && !hasOnLocationId)) {
    distType = 2
  }

  if (!establishment.distributions.includes(distType)) {
    distType = establishment.distributions[0] as DistributionType
  }

  await store.dispatch('session/storeDistributionType', distType)

  if (import.meta.env.SSR) {
    const countries = ['nl']

    const cookieOptions = {
      path: '/',
    }

    // todo .com check
    if (!countries.includes(store.state.session.country)) {
      cookieOptions.path = `/${store.state.session.country}`
    }

    ctx?.response.cookie('distribution', distType, cookieOptions)
  }

  const distributionInfo = await establishmentHttp.establishmentByDistributionType(
    slug,
    country ?? route.params.country ?? store.state.session.country,
    city ?? route.params.city,
    distType,
    location.value
  )

  return {
    ...establishment,
    ...distributionInfo,
  }
}

const initEstablishment = async () => {
  const country = route.params.country === '' ? undefined : route.params.country

  const establishment = await fetchEstablishment(
    route.params.slug,
    country ?? store.state.session.country,
    route.params.city
  )

  await store.dispatch('establishment/setEstablishment', establishment)
}

/**
 * Show a modal if the current dist type is not supported by the establishment
 */
const doesNotSupportOnLocationOrdersModal = () => {
  const distType = store.getters['session/distributionType']

  if (distType === DistributionType.OnLocation && !establishment.value?.distributionTypes.includes(DistributionType.OnLocation)) {
    const message = `${t(`modal.incorrect-dist.message-dist-${distType}`, { name: establishment.value?.title, })} ${t('modal.incorrect-dist.choose-other-option')}`

    const actions = (resolve) => [
      {
        label: t('distribution-toggle.takeaway'),
        type: DistributionType.TakeAway,
        action: () => {
          store.dispatch(
            'session/storeDistributionType',
            DistributionType.TakeAway
          )

          // navigate to the establishment page if we are on an on location page (establishment/table-1)
          router.push(establishment.value.relativeUrl)
          resolve()
        },
      },
      {
        label: t('terms.delivery'),
        type: DistributionType.Delivery,
        action: () => {
          store.dispatch(
            'session/storeDistributionType',
            DistributionType.Delivery
          )
          // navigate to the establishment page if we are on an on location page (establishment/table-1)
          router.push(establishment.value.relativeUrl)
          resolve()
        },
      },
    ].filter(({ type, }) =>
      establishment.value?.distributionTypes.includes(type)
    )

    return new Promise((resolve) => {
      store.dispatch('modal/show', {
        dataTestId: 'incorrect-dist-modal',
        title: t(`modal.incorrect-dist.title-dist-${distType}`),
        message,
        closable: false,
        callback: [
          {
            label: t('buttons.go_back'),
            action: () => {
              router.go(-1)
              resolve()
            },
            properties: {
              type: 'secondary',
            },
          },
          ...actions(resolve),
        ],
      })
    })
  }

  return true
}

const goToCheckout = async () => {
  segment.handle(CHECKOUT_STARTED, {
    basketInfo: basketInfo.value,
    establishment: establishment.value,
    tracking: store.getters['session/tracking'],
  })

  await router.push(localizedRoute({ name: 'checkout', }))
}

const { emptyBasket } = useBasket()

const openEmptyCartConfirmationModal = (type = 'empty', next) => {
  const establishment = store.getters['cart/establishment'] as Establishment

  store.dispatch('modal/show', {
    closable: true,
    dataTestId: 'empty-cart-confirmation-modal',
    title: t('confirmation-modal.title'),
    message: t(`confirmation-modal.message-${type}`, {
      establishment: establishment.title,
    }),
    callback: [
      {
        label: t('confirmation-modal.cancel-btn'),
        dataTestId: 'empty-cart-confirmation-modal-cancel',
        action: () => {
          store.dispatch('modal/reset')
          if (typeof next === 'function') next(false)
        },
        properties: {
          type: 'secondary',
        },
      },
      {
        label: t('confirmation-modal.confirm-btn'),
        dataTestId: 'empty-cart-confirmation-modal-confirm',
        action: async () => {
          await emptyBasket({
            establishment: {
              id: establishment.id,
              title: establishment.title,
            },
          })
          await store.dispatch('modal/reset')

          if (typeof next === 'function') next(true)
        },
      },
    ],
  })
}

function openProductNotAvailableModal(from: string, until: string) {
  store.dispatch('modal/show', {
    closable: true,
    dataTestId: 'product-not-available-modal',
    title: t('confirmation-modal.no-timeslots.title'),
    message: t('confirmation-modal.no-timeslots.message-no-timeslots', { from, until }),
    callback: [
      {
        label: t('terms.ok'),
        dataTestId: 'product-not-available-ok',
        properties: {
          type: 'primary',
        },
      },
    ]
  })
}

function openLocationModal(action?: { changeDistributionTo: DistributionType | undefined }) {
  state.showLocationModal = true

  if (action?.changeDistributionTo !== undefined) {
    state.changeDistributionTo = action?.changeDistributionTo
  }
}

async function updateAddress(location: Location) {
  state.locationModalError = null

  const distribution = state.changeDistributionTo ?? store.state.session.distributionType

  if (distribution === DistributionType.Delivery) {
    const country = route.params.country === '' ? undefined : route.params.country

    const distributionInfo = await establishmentHttp.establishmentByDistributionType(
      store.state.establishment.establishment?.slug,
      country ?? store.state.session.country,
      route.params.city,
      distribution,
      location
    )

    if (!distributionInfo.is_within_delivery_area) {
      state.locationModalError = t('out-of-range.message')

      return
    }

    await store.dispatch('establishment/setEstablishmentDistribution', distributionInfo)
  }

  if (state.changeDistributionTo !== null) {
    await store.dispatch('session/storeDistributionType', state.changeDistributionTo)

    state.changeDistributionTo = null
  }

  if (location.id === null) {
    await store.dispatch('session/storeTemporaryLocation', location)
  }

  await store.dispatch('session/storeCurrentLocation', location)

  state.showLocationModal = false
}

function openRemarkModal(product: BasketProduct) {
  state.remarkProduct = product
  state.showRemarkModal = true
}

function closeRemarkModal() {
  state.showRemarkModal = false
  state.remarkProduct = null
}
</script>

<template>
  <JsonLd>
    {{ structuredData }}
  </JsonLd>

  <ClientOnly>
    <FoodModal :establishment="establishment"
               @await-empty-cart-confirmation="openEmptyCartConfirmationModal"
               @open-location-modal="state.showLocationModal = true"
               @product-not-available="openProductNotAvailableModal"
               v-if="establishment !== null" />

    <InfoModal :establishment="establishment" v-if="establishment !== null" />

    <AddReviewModal :establishment="establishment" v-if="establishment !== null" />

    <RemarkModal :open="state.showRemarkModal"
                 :product="state.remarkProduct"
                 :establishment="establishment"
                 @close="closeRemarkModal"
                 v-if="establishment !== null && state.remarkProduct !== null" />

    <LocationModal v-model="state.showLocationModal"
                  closable
                  @closed="state.locationModalError = null"
                  :error="state.locationModalError"
                  @address="updateAddress" />
  </ClientOnly>

  <DefaultLayout class="background-white">
    <EstablishmentHeaderLoading v-if="establishment === null" />
    <EstablishmentHeader :establishment="establishment" v-else />

    <EstablishmentToolbarTop :search="search"
                             :establishment="establishment"
                             @show-location-modal="openLocationModal"
                             @update-search="updateSearch" />

    <EstablishmentDiscountsLoading v-if="establishment === null" />
    <EstablishmentDiscounts :establishment="establishment" v-else />

    <Container>
      <div class="establishment">
        <div class="establishment__contents establishment__padded">
          <div class="establishment__offering"
                data-test-id="establishment-menu">
            <Warning icon="warning"
                    pointer-cursor
                    data-test-id="no-location-but-should"
                    class="establishment__no-location-but-should"
                    @click="state.showLocationModal = true"
                    v-if="noLocationButShould">
              <b>{{ t('establishment.offering.warning') }}:</b>
              {{ t('establishment.offering.message') }}
            </Warning>

            <template v-if="establishment !== null">
              <div v-if="filteredProducts.length === 0" class="establishment__no-products">
                <Warning icon="warning" data-test-id="no-products">
                  {{ t('establishment.offering.no-products') }}
                </Warning>
              </div>
              <EstablishmentCategory v-for="productSet in filteredProducts"
                                      :key="productSet.id"
                                      :data="productSet"
                                      :establishment="establishment" />
            </template>
            <template v-else>
              <LoadingEstablishmentCategory />
              <LoadingEstablishmentCategory />
            </template>
          </div>
        </div>

        <div class="sidebar">
          <div class="sidebar__sticky">
            <ClientOnly>
              <!-- TODO: replace with a simple loading state -->
              <Cart data-test-id="establishment-cart"
                    class="sidebar__cart"
                    no-tip
                    @confirm="goToCheckout"
                    @open-instructions-modal="openRemarkModal" />
            </ClientOnly>
          </div>
        </div>
      </div>

      <EstablishmentFAQ :establishment="establishment"
                        class="content mb-10"
                        v-if="establishment"/>

      <!-- eslint-disable vue/no-v-html -->
      <div v-if="seoContent"
            class="pb-10 content"
            v-html="seoContent"
      />
      <!-- eslint-enable vue/no-v-html -->
    </Container>

    <ClientOnly>
      <Toolbar v-if="cartEstablishment && store.getters['cart/hasCart'] && hasItemsFromEstablishment"
                class="establishment__toolbar">
        <template #top>
          <FreeDeliveryNoticeDrawer v-if="store.getters['cart/basketInfo'] !== null" />
        </template>
        <div class="toolbar__price">
          <Icon name="shopping-basket-line" /> {{ totalPayable }}
        </div>
        <Button data-test-id="toolbar-checkout-button" class="checkout-btn" @click="goToCheckout">{{ t('checkout-button.to-checkout') }}</Button>
      </Toolbar>
    </ClientOnly>
  </DefaultLayout>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/breakpoints-down.scss';

.checkout-btn.button {
  @include lg-down {
    width: 100%;
  }
}
.establishment-seo-information {
  display: none;
}

.establishment {
  display: flex;

  &__contents {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem 2rem;
    width: 100%;

    @include lg-up {
      padding: 0 0 2rem 0;
      margin-right: 2rem;
      width: calc(100% - 25rem - 2rem);
    }

    @include xl-up {
      margin-right: 4rem;
      width: calc(100% - 25rem - 4rem);
    }

    @include xl-up {
      margin-right: 4rem;
      width: calc(100% - 25rem - 4rem);
    }
  }

  &__no-products {
    margin-top: 2rem;
  }

  &__sidebar {
    margin-left: auto;
  }

  &__offering {
    order: 3;
    margin-top: -0.6rem;

    @include lg-up {
      margin-top: 1rem;
    }
  }

  &__no-location-but-should {
    margin-top: 1rem;
  }

  &__toolbar {
    &:deep(.toolbar__content) {
      gap: 1.5rem;
      // padding: 1.125rem 1.5rem calc(1.125rem + env(safe-area-inset-bottom)) 2rem;
      padding: 0 1.5rem 0 2rem;
      margin-bottom: env(safe-area-inset-bottom);
      height: 56px;
      justify-content: center;
      align-items: center;

      @include md-up {
        height: 88px;
      }
    }
  }
}

.content {
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  @include lg-up {
    margin-right: 0;
    margin-left: 0;
  }
}

.establishment-discounts {
  margin-top: 2rem;
}

// TODO: remove after redesign
.background-white {
  background-color: #fff;
}

.toolbar {
  &__price {
    display: flex;
    width: max-content;
    font-weight: 700;
    align-items: center;
    gap: 0.5rem;
  }
}

.sidebar {
  display: none;
  width: 25rem;

  @include lg-up {
    display: block;
  }

  &__sticky {
    max-height: 680px;
    position: sticky;
    top: 6rem;
  }

  &__cart {
    margin: 4rem 0;
  }
}
</style>
